<template>
  <div class="center_block1">
    <!-- <select-city @on-change="selectCityChange"></select-city> -->
    <div class="data_box">
      <!-- <div class="install_number_box">
        <h3>安装点总数[个]</h3>
        <digital-flop :config="{ number: [centerData.totalLocaleCount], style: { fontSize: 40, fill: '#CDA52B', fontWeight: 'bold' } }" :styles="{ width: '100%', height: '35px'}"></digital-flop>
      </div> -->
      <ul>
        <li>
          <h3>监测点总数[个]</h3>
          <h4>{{centerData.totalDeviceInstalledCount}}</h4>
          <!-- <digital-flop :config="{ number: [centerData.totalDeviceInstalledCount], style: { fontSize: 16, fill: '#CDA52B' } }" :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop> -->
        </li>
        <li>
          <h3>在线数[台]</h3>
          <h4>{{centerData.onlineDeviceCount}}</h4>
          <!-- <digital-flop :config="{ number: [centerData.onlineDeviceCount], style: { fontSize: 16, fill: '#CDA52B' } }" :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop> -->
        </li>
        <li>
          <h3>达标数[台]</h3>
          <h4>{{centerData.complianceDeviceCount}}</h4>
          <!-- <digital-flop :config="{ number: [centerData.complianceDeviceCount], style: { fontSize: 16, fill: '#CDA52B' } }" :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop> -->
        </li>
        <li>
          <h3>超标数[台]</h3>
          <h4>{{centerData.notComplianceDeviceCount}}</h4>
          <!-- <digital-flop :config="{ number: [centerData.notComplianceDeviceCount], style: { fontSize: 16, fill: '#CDA52B' } }" :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop> -->
        </li>
        <li>
          <h3>PM2.5昨日减排[Kgs]</h3>
          <h4>{{centerData.redPm25Amount}}</h4>
          <!-- <digital-flop :config="{ number: [centerData.redPm25Amount], style: { fontSize: 16, fill: '#CDA52B' } }" :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop> -->
        </li>
        <li>
          <h3>PM10昨日减排[Kgs]</h3>
          <h4>{{centerData.redPm10Amount}}</h4>
          <!-- <digital-flop :config="{ number: [centerData.redPm10Amount], style: { fontSize: 16, fill: '#CDA52B' } }" :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop> -->
        </li>
        <li>
          <h3>非甲烷总烃昨日减排[Kgs]</h3>
          <h4>{{centerData.redVocsAmount}}</h4>
          <!-- <digital-flop :config="{ number: [centerData.redVocsAmount], style: { fontSize: 16, fill: '#CDA52B' } }" :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop> -->
        </li>
        <li>
          <h3>油烟昨日减排[Kgs]</h3>
          <h4>{{centerData.redEmissionAmount}}</h4>
          <!-- <digital-flop :config="{ number: [centerData.redEmissionAmount], style: { fontSize: 16, fill: '#CDA52B' } }" :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop> -->
        </li>
      </ul>
    </div>
    <div class="map_block">
      <dot-map :content-data="curArea"></dot-map>
    </div>
    <div class="bottom_block">
      <online-platform :content-data="centerData.onlineDeviceCountByDate"></online-platform>
      <center-reach-rate :content-data="centerData.complianceDeviceRatioByDate"></center-reach-rate>
    </div>
  </div>
</template>

<script>
// import { queryToMD5 } from "@/utils/md5_query"
// import selectCity from './city'
import onlinePlatform from './onlinePlatform'
import centerReachRate from './centerReachRate'
// import dotMap from './sctterMapQu'
// import digitalFlop from '../decorations/digitalFlop'

export default {
  name: 'center',
  components: {
    // selectCity,
    // digitalFlop,
    onlinePlatform,
    centerReachRate,
    // dotMap
  },
  props: ['contentData'],
  data() {
    return {
      timer: null, // 时间对象
      curArea: null, // 当前区域
      centerData: {
        totalLocaleCount: 0, // 安装点总数
        totalDeviceCount: 0, // 监测点总数
        onlineDeviceCount: 0, // 在线数
        redPm25Amount: 0, // PM2.5实时减排量
        redPm10Amount: 0, // PM10实时减排量
        complianceDeviceCount: 0, // 达标数
        notComplianceDeviceCount: 0, // 超标数
        redVocsAmount: 0, // 非甲烷总烃减排量昨日
        redEmissionAmount: 0, // 油烟实时减排总量
        onlineDeviceCountByDate: [], // 台数
        complianceDeviceRatioByDate: [] // 达标率
      }
    }
  },
  created() {
  },
  methods: {
    /**
     * @description 选择区域
     * @param {Object} area 区域
     */
    selectCityChange(area, n) {
      this.curArea = Object.assign({}, area, this.contentData)
      this.$emit('cityChange', area)
    },
  },
  watch: {
    contentData: {
      handler: function (data) {
        // console.log(data,'---')
        this.centerData = Object.assign({}, data.sumData)
        this.curArea = Object.assign({}, data)
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.center_block1 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  height: 90vh;

  .map_block {
    flex: 1;
    height: 64vh;
    // margin-top: 14px;
  }

  .data_box {
    display: flex;
    height: 7vh;
    align-items: center;
    ul {
      flex: 1;
      display: flex;
      justify-content: space-between;
      // flex-wrap: nowrap;
      // padding-left: 94px;
      margin: 0;

      li {
        // width: 12.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;

        h3 {
          font-size: 10px;
          color: #B2F2F6;
          margin: 0;
          font-weight: normal;
          text-align: center;
        }
        h4{
          text-align: center;
          font-size: 16px;
          color: #CDA52B;
          margin: 0;
        }
      }
    }
  }

  .bottom_block {
    display: flex;
    height: 13vh;
    >div {
      flex: 1;
    }
  }

}
</style>
